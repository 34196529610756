import {useCallback, useMemo, useState} from 'react';
import {StartRecalculationJob} from "./components/StartRecalculationJob";
import queryString from "query-string";
import {useLocation} from "react-router-dom";
import {LocalizationProvider, DatePicker} from '@mui/x-date-pickers';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFnsV3';

import {Header, Warning, DatePickerWrapper, StartJobWrapper} from './styled';

export function NewRecalculationJob() {
    const [fromDate, setFromDate] = useState(new Date(2020, 0, 1));
    const [toDate, setToDate] = useState(new Date());
    const [recalculatedBeforeDate, setrecalculatedBeforeDate] = useState(new Date());

    const handleFromDateChange = useCallback((date: any) => {
        setFromDate(date);
    }, []);
    const handleToDateChange = useCallback((date: any) => {
        setToDate(date);
    }, []);
    const handlerecalculatedBeforeDateChange = useCallback((date: any) => {
        setrecalculatedBeforeDate(date);
    }, []);

    const location = useLocation();
    const hasActiveJob = useMemo(() => {
        const queryParams = queryString.parse(location.search);
        return queryParams.hasActiveJob === 'true';
    }, [location.search]);

    return (
        <div>
            <Header>
                <h1>Create a new recalculation job!</h1>
                {hasActiveJob && (
                    <Warning>
                        Note: A recalculation job is currently running, and you won't be able to create a new one until it's completed.
                    </Warning>
                )}
            </Header>
            <DatePickerWrapper>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <div>
                        <DatePicker
                            format="yyyy-MM-dd"
                            label="From"
                            value={fromDate}
                            onChange={handleFromDateChange}
                            maxDate={toDate ? toDate : undefined}
                        />

                        <DatePicker
                            format="yyyy-MM-dd"
                            label="To"
                            value={toDate}
                            onChange={handleToDateChange}
                            minDate={fromDate ? fromDate : undefined}
                            maxDate={new Date()}
                        />
                    </div>
                    <div>
                        <DatePicker
                            format="yyyy-MM-dd hh:mm:ss"
                            label="Ignore if recalculated after"
                            value={recalculatedBeforeDate}
                            onChange={handlerecalculatedBeforeDateChange}
                            minDate={fromDate ? fromDate : undefined}
                        />
                    </div>
                </LocalizationProvider>
            </DatePickerWrapper>
            <StartJobWrapper>
                <StartRecalculationJob
                    fromDate={fromDate}
                    toDate={toDate}
                    recalculatedBeforeDate={recalculatedBeforeDate}
                    isDisabled={hasActiveJob}
                />
            </StartJobWrapper>
        </div>
    );
}

