import styled from "styled-components";

export const StartJobWrapper = styled.div`
    max-width: 480px;
    margin: 20px auto;
`;

export const Header = styled.div`
    max-width: 480px;
    margin: 0 auto;
`;

export const Warning = styled.p`
    color: red;
`;

export const DatePickerWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;
